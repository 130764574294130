import React, { useState } from "react";

import {
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBAnimation,
} from "mdbreact";

import { CECI, GABI, MIGUEL } from "../../assets/images";

import { Container } from "./styles";

export default function Team() {
  const [showBio, setShowBio] = useState(false);

  const handleShowBio = (partner) => {
    setShowBio(!showBio);
    setActiveItems(partner);
  };

  const [activeItem, setActiveItems] = useState("1");

  const togglePills = (tab) => {
    setActiveItems(tab);
  };

  return (
    <Container id="team" showBio={showBio}>
      <MDBContainer>
        <MDBRow className="mb-2 mt-3">
          <MDBCol lg="4" md="4" sm="4">
            <div className="white-line-left" />
          </MDBCol>
          <MDBCol lg="4" md="4" sm="4" className="text-center">
            <h1>NOSSA EQUIPE</h1>
          </MDBCol>
          <MDBCol lg="4" md="4" sm="4">
            <div className="white-line-right" />
          </MDBCol>
        </MDBRow>
        <div className="wrapper-photos">
          <MDBRow className="full-width">
            <MDBCol className="text-center">
              <span>
                O trabalho da nossa equipe é realizado de forma artesanal, e tem
                como base relações de confiança, diálogo e parceria com nossos
                clientes, desenvolvendo, assim, estratégias jurídicas eficientes
                e adequadas a cada caso concreto. Atuamos com responsabilidade,
                priorizando a revolução e a liberdade.
              </span>
            </MDBCol>
          </MDBRow>
          {!showBio ? (
            <>
              <MDBRow className="full-width mt-3 align-items-center justify-content-center">
                <MDBCol lg="4" md="4" sm="12" className="text-center">
                  <div className="partner-wrapper">
                    <div
                      className="img-overlay"
                      onClick={() => handleShowBio("1")}
                    >
                      Ver Bio
                    </div>
                    <img src={GABI} width="180" alt="" className="image" />

                    <span className="text-name mt-1">Gabriella Arima</span>
                    <span className="text-title">Sócia</span>
                  </div>
                </MDBCol>
                <MDBCol lg="4" md="4" sm="12" className="text-center">
                  <div className="partner-wrapper">
                    <div
                      className="img-overlay"
                      onClick={() => handleShowBio("2")}
                    >
                      Ver Bio
                    </div>
                    <img src={CECI} width="180" alt="" className="image" />
                    <span className="text-name mt-1">Cecília Galício</span>
                    <span className="text-title">Sócia</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-1 full-width">
                <MDBCol
                  lg="12"
                  md="12"
                  sm="12"
                  className="d-flex align-items-center justify-content-center"
                >
                  <MDBCol lg="4" md="4" sm="12">
                    <div className="partner-wrapper">
                      <div
                        className="img-overlay"
                        onClick={() => handleShowBio("3")}
                      >
                        Ver Bio
                      </div>
                      <img src={MIGUEL} width="180" alt="" className="image" />
                      <span className="text-name mt-1">Miguel Kupermann</span>
                      <span className="text-title">Advogado</span>
                    </div>
                  </MDBCol>
                </MDBCol>
              </MDBRow>
            </>
          ) : (
            <MDBAnimation type="fadeIn" delay=".3s">
              <MDBContainer>
                <MDBRow>
                  <MDBCol md="3">
                    <MDBNav pills className="flex-column">
                      <MDBNavItem>
                        <MDBNavLink
                          link
                          to="#"
                          active={activeItem === "1"}
                          onClick={() => togglePills("1")}
                        >
                          <img
                            src={GABI}
                            width="180"
                            alt=""
                            className="image"
                          />
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink
                          link
                          to="#"
                          active={activeItem === "2"}
                          onClick={() => togglePills("2")}
                        >
                          <img
                            src={CECI}
                            width="180"
                            alt=""
                            className="image"
                          />
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink
                          link
                          to="#"
                          active={activeItem === "3"}
                          onClick={() => togglePills("3")}
                        >
                          <img
                            src={MIGUEL}
                            width="180"
                            alt=""
                            className="image"
                          />
                        </MDBNavLink>
                      </MDBNavItem>
                    </MDBNav>
                  </MDBCol>
                  <MDBCol md="9">
                    <MDBTabContent activeItem={activeItem}>
                      <MDBTabPane tabId="1">
                        <MDBAnimation type="fadeIn" delay=".3s">
                          <MDBCardBody>
                            <MDBCardTitle>Gabriella Arima - Sócia</MDBCardTitle>
                            <MDBCardText>
                              Formada com menção honrosa pela Pontifícia
                              Universidade Católica de São Paulo (PUCSP - 2016),
                              com extensão em Política Públicas pela mesma
                              instituição. É especialista em litigância
                              estratégica e advocacy, tendo atuado em grandes
                              escritórios corporativos. É membra da Rede
                              Jurídica pela Reforma da Política de Drogas - Rede
                              Reforma, do Núcleo de Álcool, Outras Drogas e
                              Saúde Mental da Comissão de Direitos Humanos da
                              OAB/SP, é Conselheira Suplente do Conselho
                              Estadual de Política sobre Drogas de São Paulo -
                              CONED/SP, Promotora Legal Popular pela União de
                              Mulheres de São Paulo e integrante da RENFA - Rede
                              Nacional de Feministas Antiproibicionistas.
                            </MDBCardText>
                          </MDBCardBody>
                        </MDBAnimation>
                      </MDBTabPane>
                      <MDBTabPane tabId="2">
                        <MDBAnimation type="fadeIn" delay=".3s">
                          <MDBCardBody>
                            <MDBCardTitle>Cecilia Galicio - Sócia</MDBCardTitle>
                            <MDBCardText>
                              Formada pela Faculdade de Direito de São Bernardo
                              (2005), mestre em Direito Internacional Público
                              pela Universidade de Lisboa. Ministra aulas em
                              cursos livres e de pós-graduação. Integra a
                              diretoria da Rede Jurídica pela Reforma da
                              Política de Drogas - Rede Reforma, membra do
                              Núcleo de Álcool, Outras Drogas e Saúde Mental da
                              Comissão de Direitos Humanos da OAB/SP, é
                              voluntária na Accura – Associação Cannabis Cura. É
                              Conselheira Suplente do Conselho Estadual de
                              Política sobre Drogas de São Paulo - CONED/SP e
                              integrante da RENFA - Rede Nacional de Feministas
                              Antiproibicionistas.
                            </MDBCardText>
                          </MDBCardBody>
                        </MDBAnimation>
                      </MDBTabPane>
                      <MDBTabPane tabId="3">
                        <MDBAnimation type="fadeIn" delay=".3s">
                          <MDBCardBody>
                            <MDBCardTitle>
                              Miguel Kupermann - Advogado
                            </MDBCardTitle>
                            <MDBCardText>
                              Advogado criminalista, bacharel em direito pela
                              PUC-SP, tendo sido graduado com “Menção Honrosa”
                              referente ao trabalho de conclusão de curso
                              intitulado “A efetividade do Habeas Corpus na
                              proteção de direitos fundamentais: uma perspectiva
                              do direito ao cultivo da Cannabis para fins
                              medicinais sob a ótica do direito à saúde e à
                              liberdade individual”. Pesquisador na mesma
                              instituição, vinculado ao grupo de pesquisa
                              “Sistema de Justiça e Estado de Exceção”. Membro
                              do Instituto de Defesa do Direito de Defesa
                              (IDDD), do Instituto Brasileiro de Ciências
                              Criminais (IBCCRIM) e da Rede Jurídica pela
                              Reforma da Política de Drogas - Rede Reforma. Faz
                              parte do Observatório antiproibicionista da
                              Faculdade de Direito da USP e é membro do Núcleo
                              de Política de Drogas e Saúde Mental da Comissão
                              Permanente de Direitos Humanos da OAB/SP.
                            </MDBCardText>
                          </MDBCardBody>
                        </MDBAnimation>
                      </MDBTabPane>
                    </MDBTabContent>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBAnimation>
          )}
        </div>
      </MDBContainer>
    </Container>
  );
}
